"use client"

import {
  DialogContent,
  DialogTitle,
  DialogHeader,
  Dialog,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog"
import { CustomIcon } from "../custom-icon"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import React, { useEffect } from "react"

export function showPageDialog(data: PageDialogProps) {
  const event = new CustomEvent("page-dialog", {
    detail: data,
  })
  window.dispatchEvent(event)
}

interface PageDialogProps {
  isOpen: boolean
  type: "normal" | "error" | "warning"
  title: string
  mainMessage: string
  onClose: () => void
  onCloseText: string
  onConfirm: null | any
  onConfirmText: string
  dialogDescription?: React.ReactNode | null
}

export const PageDialog: React.FC = () => {
  const [data, setData] = React.useState<PageDialogProps>({
    isOpen: false,
    type: "normal",
    title: "Supprimer la tâche",
    mainMessage: "Êtes-vous sûr de vouloir supprimer cette tâche ?",
    onClose: () => {},
    onCloseText: "Annuler",
    onConfirm: () => {},
    onConfirmText: "Supprimer",
    dialogDescription: null,
  })

  useEffect(() => {
    window.addEventListener("page-dialog", (e: any) => {
      setData(e.detail)
    })

    return () => {
      window.removeEventListener("page-dialog", (e: any) => {
        setData(e.detail)
      })
    }
  }, [])

  const {
    isOpen,
    type,
    title,
    mainMessage,
    onClose,
    onCloseText,
    onConfirm,
    onConfirmText,
    dialogDescription,
  } = data

  return (
    <>
      <div id="page-dialog"></div>
      <Dialog open={isOpen}>
        <DialogContent
          className={cn(
            type === "error" && "border-t-8 border-t-error-text",
            type === "warning" && "border-t-8 border-t-warning-deco",
          )}
        >
          <DialogHeader>
            <DialogTitle className="flex gap-4 items-center">
              {type === "error" && (
                <CustomIcon type="Icon-Error" className="!h-8 !w-8 text-error-text" />
              )}
              {type === "warning" && (
                <CustomIcon type="Icon-Warning" className="!h-8 !w-8 text-warning-deco" />
              )}
              <div className="text-700-24">{title}</div>
            </DialogTitle>
            {dialogDescription ? (
              <DialogDescription className="ml-12 !mt-8">
                {dialogDescription}
              </DialogDescription>
            ) : (
              <DialogDescription
                className={
                  cn("!my-8", (type === "error" || type === "warning") && "ml-12") +
                  " text-500-16"
                }
              >
                <div>{mainMessage}</div>
              </DialogDescription>
            )}

            <DialogFooter className="h-[65px] items-end  justify-end">
              <Button
                type="button"
                variant="forecastOutline"
                onClick={(_) => {
                  setData({ ...data, isOpen: false })
                  onClose()
                }}
              >
                {onCloseText}
              </Button>
              {onConfirm && (
                <Button
                  type="button"
                  variant="forecast"
                  onClick={(_) => {
                    setData({ ...data, isOpen: false })
                    onConfirm()
                  }}
                >
                  {onConfirmText}
                </Button>
              )}
            </DialogFooter>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  )
}
