import Image from "next/image"
import { MouseEventHandler } from "react"

export type CustomIconProps = {
  type:
    | "Icon-Add"
    | "Icon-attach-file"
    | "Icon-calendar"
    | "Icon-check-done-success"
    | "Icon-Chevron-Down"
    | "Icon-Chevron_left"
    | "Icon-Chevron_right"
    | "Icon-clock"
    | "Icon-Close"
    | "Icon-delete-garbage"
    | "Icon-Edit"
    | "Icon-empty-box"
    | "Icon-Empty-Space"
    | "Icon-Error"
    | "Icon-File-attached"
    | "Icon-File"
    | "Icon-Filter-Off"
    | "Icon-Filter-On"
    | "Icon-History"
    | "Icon-Image"
    | "Icon-Info"
    | "Icon-More-Actions-2"
    | "Icon-More-Actions"
    | "Icon-preview"
    | "Icon-Print"
    | "Icon-printed"
    | "Icon-received"
    | "Icon-Rejected"
    | "Icon-satus-approved"
    | "Icon-satus-closed"
    | "Icon-satus-open"
    | "Icon-satus-open-blue"
    | "Icon-Search"
    | "Icon-stop-cancel-remove"
    | "Icon-SubTasks"
    | "Icon-Team_1"
    | "Icon-Team_2"
    | "Icon-Transferred"
    | "Icon-unchecked-done-success"
    | "Icon-upload"
    | "Icon-Warning"
    | "Icon-Worker-2"
    | "Icon-Worker"
    | "Icons black"
    | "Incon-download"
    | "Icon-contact"
    | "Icon-Copy"
    | "Icon-Mail"
    | "Icon-Phone"
    | "Icon-Template"
    | "Icon-from-scratch"
    | "Icon-Equipment_supplies"
    | "Icon-Comments"
    | "Icon-Request_contact"
    | "Icon-Signature"

  className?: string
  style?: React.CSSProperties
  height?: number
  width?: number
  onClick?: MouseEventHandler<HTMLImageElement> | undefined
}
export function CustomIcon(props: CustomIconProps) {
  return (
    <Image
      width={props.width ?? 16}
      height={props.height ?? 16}
      alt="icon"
      src={"/assets/icons/" + props.type + ".svg"}
      onClick={props.onClick}
      {...props}
      className={props.className?.replace("text-", "filter-")}
    />
  )
}
